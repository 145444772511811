import { push, replace } from 'react-router-redux'
import debounce from 'lodash/debounce'
import merge from 'lodash/merge'
import get from 'lodash/get'
import omit from 'lodash/omit'
import qs from 'query-string'
import dedent from 'dedent'

import config from 'config'
import env from 'env'

import fetchWrapper, {
  FetchMethod,
  FetchOptions,
} from 'shared/tools/fetch-wrapper'

import {
  loadFeaturedProduct,
  loadUserSubscriptions,
  subscriptionAccessLevelsClear,
} from 'client/bookmate/reducers/subscription-reducer'
import * as languagesActions from 'client/shared/reducers/languages-reducer'
import { setAvailableUserLanguages } from 'client/shared/reducers/languages-reducer'
import urlFor, {
  addParamsToPath,
  getDomain,
  getSubdomainFromHostname,
  getSubdomainFromLocale,
  NullableQueryParams,
  QueryParams,
} from 'shared/tools/url-helper'
import { isUsernameValid } from 'client/bookmate/helpers/user-helpers'
import { isKnownBotUa } from 'shared/tools/robots-helper'

import { NOTIFICATIONS_MARK_AS_READ } from 'client/shared/reducers/notifications-reducer'
import { cleanShowcase } from 'client/bookmate/reducers/showcase-reducer'
import {
  ACCOUNT_DELETED,
  analyticsEvent,
  LOGGED_OUT,
  VERIFICATION_CODE_REQUESTED,
} from 'client/shared/reducers/analytics-reducer'
import { externalRedirect } from 'client/shared/reducers/app-reducer'
import { showAlert } from 'client/shared/reducers/alert-reducer'

import {
  ApiAction,
  CALL_API,
  UNKNOWN_ERROR_ID,
} from 'shared/middlewares/api-middleware'
import { hide as hideAuthPopup } from 'client/shared/reducers/popup-reducer'
import storage from 'client/shared/helpers/storage'
import {
  Dispatch,
  GenericDispatchedEvent,
  GetState,
  ThunkAction,
} from 'shared/types/redux'
import {
  CurrentUserData,
  CurrentUserState,
  CurrentUserUI,
  Locale,
  UserPrivacySettings,
} from 'client/shared/types/current-user'
import { changeStep, SocialNetwork } from './auth-reducer'
import { UserDevice } from 'client/bookmate/boxes/user-settings-box'

const CURRENT_USER_LOAD = 'CURRENT_USER_LOAD'
const CURRENT_USER_LOAD_SUCCESS = 'CURRENT_USER_LOAD_SUCCESS'
const CURRENT_USER_LOAD_ERROR = 'CURRENT_USER_LOAD_ERROR'

const CURRENT_USER_CHECK_NOTIFICATIONS = 'CURRENT_USER_CHECK_NOTIFICATIONS'
const CURRENT_USER_CHECK_NOTIFICATIONS_SUCCESS =
  'CURRENT_USER_CHECK_NOTIFICATIONS_SUCCESS'

const CURRENT_USER_DEVICES_LOAD = 'CURRENT_USER_DEVICES_LOAD'
const CURRENT_USER_DEVICES_LOAD_SUCCESS = 'CURRENT_USER_DEVICES_LOAD_SUCCESS'
const CURRENT_USER_DEVICE_DELETE = 'CURRENT_USER_DEVICE_DELETE'
const CURRENT_USER_DEVICE_UPDATE = 'CURRENT_USER_DEVICE_UPDATE'

const CURRENT_USER_PRIVACY_LOAD = 'CURRENT_USER_PRIVACY_LOAD'
const CURRENT_USER_PRIVACY_LOAD_SUCCESS = 'CURRENT_USER_PRIVACY_LOAD_SUCCESS'
const CURRENT_USER_PRIVACY_LOAD_FAILED = 'CURRENT_USER_PRIVACY_LOAD_FAILED'

const CURRENT_USER_PRIVACY_UPDATE_REQUEST =
  'CURRENT_USER_PRIVACY_UPDATE_REQUEST'
const CURRENT_USER_PRIVACY_UPDATE_SUCCESS =
  'CURRENT_USER_PRIVACY_UPDATE_SUCCESS'
const CURRENT_USER_PRIVACY_UPDATE_FAILED = 'CURRENT_USER_PRIVACY_UPDATE_FAILED'

const CURRENT_USER_COMPLETELY_LOADED = 'CURRENT_USER_COMPLETELY_LOADED'

const CURRENT_USER_LOGOUT_SUCCESS = 'CURRENT_USER_LOGOUT_SUCCESS'
const CURRENT_USER_LOGOUT_ERROR = 'CURRENT_USER_LOGOUT_ERROR'

const CURRENT_USER_LOCALE_CHANGED = 'CURRENT_USER_LOCALE_CHANGED'
export const CURRENT_USER_LIBRARY_LANGUAGE_CHANGED =
  'CURRENT_USER_LIBRARY_LANGUAGE_CHANGED'

const CURRENT_USER_SETTINGS_UPDATE = 'CURRENT_USER_SETTINGS_UPDATE'
const CURRENT_USER_SOCIALS_UPDATE = 'CURRENT_USER_SOCIALS_UPDATE'
const CURRENT_USER_UI_UPDATE = 'CURRENT_USER_UI_UPDATE'

const CURRENT_USER_AVATAR_LOAD_SUCCESS = 'CURRENT_USER_AVATAR_LOAD_SUCCESS'

const CURRENT_USER_EMAIL_UPDATE = 'CURRENT_USER_EMAIL_UPDATE'
const CURRENT_USER_EMAIL_UPDATE_SUCCESS = 'CURRENT_USER_EMAIL_UPDATE_SUCCESS'
const CURRENT_USER_EMAIL_UPDATE_ERROR = 'CURRENT_USER_EMAIL_UPDATE_ERROR'
const APP_LINK_SEND = 'APP_LINK_SEND'
const APP_LINK_SEND_SUCCESS = 'APP_LINK_SEND_SUCCESS'
const APP_LINK_SEND_ERROR = 'APP_LINK_SEND_ERROR'
const REQUEST_CODE = 'REQUEST_CODE'
const REQUEST_CODE_SUCCESS = 'REQUEST_CODE_SUCCESS'
const SUBSCRIBE_TO_MAILING_GROUP = 'SUBSCRIBE_TO_MAILING_GROUP'
const SUBSCRIBE_TO_MAILING_GROUP_SUCCESS = 'SUBSCRIBE_TO_MAILING_GROUP_SUCCESS'

const UNSUBSCRIBE_FROM_MAILING_GROUP = 'UNSUBSCRIBE_FROM_MAILING_GROUP'
const UNSUBSCRIBE_FROM_MAILING_GROUP_SUCCESS =
  'UNSUBSCRIBE_FROM_MAILING_GROUP_SUCCESS'

const LOAD_MAILING_GROUPS = 'LOAD_MAILING_GROUPS'
const LOAD_MAILING_GROUPS_SUCCESS = 'LOAD_MAILING_GROUPS_SUCCESS'

const SOCIAL_AUTH_SUCCESS = 'SOCIAL_AUTH_SUCCESS'
const AUTH_FORM_LOGIN_SUCCESS = 'AUTH_FORM_LOGIN_SUCCESS'

const SEND_CONFIRM_EMAIL_MAIL = 'SEND_CONFIRM_EMAIL_MAIL'
const SEND_CONFIRM_EMAIL_MAIL_SUCCESS = 'SEND_CONFIRM_EMAIL_MAIL_SUCCESS'

const DEBOUNCE_TIME = 1000
const SHORT_DEBOUNCE_TIME = 100

export const USER_ID = 'user_id'

type CurrentUserLoadSuccessMinimalPayload = {
  country: string
  locale: string
  library_lang: string
  audio: boolean
  comics: boolean
}

type DeleteAccountPayload = {
  user: {
    current_password?: string
    code?: string
    removing_reason: string
  }
}

type CurrentUserLoadSuccessAction = {
  type: 'CURRENT_USER_LOAD_SUCCESS'
  currentUser: {
    auth: boolean
    team?: boolean
    data: CurrentUserLoadSuccessMinimalPayload | CurrentUserData
    mailingGroups?: MailingGroup[]
    ui: CurrentUserUI
  }
}

type SendConfirmEmailMailAction = {
  type: typeof SEND_CONFIRM_EMAIL_MAIL
}

type SendConfirmEmailMailSuccessAction = {
  type: typeof SEND_CONFIRM_EMAIL_MAIL_SUCCESS
}

type CurrentUserLoadAction = {
  type: 'CURRENT_USER_LOAD'
}

type CurrentUserLoadErrorAction = {
  type: 'CURRENT_USER_LOAD_ERROR'
}

type CurrentUserLoadCompleteAction = {
  type: 'CURRENT_USER_COMPLETELY_LOADED'
}

type CurrentUserLoadDevicesSuccessAction = {
  type: 'CURRENT_USER_DEVICES_LOAD_SUCCESS'
  devices: UserDevice[]
}

type CurrentUserLogoutSuccessAction = {
  type: 'CURRENT_USER_LOGOUT_SUCCESS'
}
type SendAppLinkAction = {
  type: 'APP_LINK_SEND'
}

type SendAppLinkSuccessAction = {
  type: 'APP_LINK_SEND_SUCCESS'
}

type SendAppLinkErrorAction = {
  type: 'APP_LINK_SEND_ERROR'
  error: string | { message: string }
}
type CurrentUserSocialsUpdateAction = {
  type: 'CURRENT_USER_SOCIALS_UPDATE'
  data: { social_networks: SocialNetwork }
}

type CurrentUserEmailUpdateAction = {
  type: 'CURRENT_USER_EMAIL_UPDATE'
}

type CurrentUserEmailUpdateSuccessAction = {
  type: 'CURRENT_USER_EMAIL_UPDATE_SUCCESS'
}

type CurrentUserEmailUpdateErrorAction = {
  type: 'CURRENT_USER_EMAIL_UPDATE_ERROR'
  error: { message: string }
}

type CurrentUserLogoutErrorAction = {
  type: 'CURRENT_USER_LOGOUT_ERROR'
}

type CurrentUserLocaleChangedAction = {
  type: 'CURRENT_USER_LOCALE_CHANGED'
  locale: string
}

type CurrentUserSocialAuthSuccessAction = {
  type: 'SOCIAL_AUTH_SUCCESS'
  provider: string
}

type CurrentUserAuthFormLoginSuccess = {
  type: 'AUTH_FORM_LOGIN_SUCCESS'
  data: CurrentUserData
}

type CurrentUserLibraryLanguageChangedAction = {
  type: 'CURRENT_USER_LIBRARY_LANGUAGE_CHANGED'
  libraryLanguage: string
}

export type MailingGroup = {
  description: string
  editable: boolean
  enabled: boolean
  name: string
  title: string
}

type LoadMailingGroupsAction = {
  mailing_groups: MailingGroup[]
  type: 'LOAD_MAILING_GROUPS_SUCCESS'
}

type SubscribeToMailingGroupAction = {
  type: 'SUBSCRIBE_TO_MAILING_GROUP_SUCCESS'
}

type UnsubscribeFromMailingGroupAction = {
  type: 'UNSUBSCRIBE_FROM_MAILING_GROUP_SUCCESS'
}

type CurrentUserUIUpdateAction = {
  type: 'CURRENT_USER_UI_UPDATE'
  ui: {
    avatar?: {
      loading: boolean
      error?: boolean | string
    }
    login?: {
      error: string
    }
    password?: {
      loading: boolean
      error: string
    }
    email?: {
      loading: boolean
      error: boolean | string
    }
    phone?: {
      loading: boolean
      confirmationCodeError?: boolean | string
      phoneNumberError?: boolean | string
    }
    mailingSubscription?: {
      loading: boolean
      error?: string
    }
  }
  data?: {
    avatar_url: string
    library_lang: string
  }
}

type CurrentUserAvatarLoadSuccessAction = {
  type: 'CURRENT_USER_AVATAR_LOAD_SUCCESS'
  ui: {
    avatar: {
      loading: false
      error: false
    }
  }
  data: {
    avatar_url: string
    library_lang: string
  }
}

type CurrentUserSettingsUpdateAction = {
  type: 'CURRENT_USER_SETTINGS_UPDATE'
  data: {
    [key: string]: (string | null | undefined) | boolean
  }
  ui?: {
    login?: {
      error: boolean
    }
    password?: {
      error: boolean
      loading: boolean
    }
    mailingSubscription?: {
      loading: boolean
    }
  }
}

type CurrentUserCheckNotificationsAction = {
  type: 'CURRENT_USER_CHECK_NOTIFICATIONS'
}

type CurrentUserCheckNotificationsSuccessAction = {
  type: 'CURRENT_USER_CHECK_NOTIFICATIONS_SUCCESS'
  has_unread_notifications: boolean
}

type CurrentUserMarkNotificationsReadAction = {
  type: 'NOTIFICATIONS_MARK_AS_READ'
}

type CurrentUserPrivacyLoadAction = {
  readonly type: typeof CURRENT_USER_PRIVACY_LOAD
}

type CurrentUserPrivacyLoadFailedAction = {
  readonly type: typeof CURRENT_USER_PRIVACY_LOAD_FAILED
}

type CurrentUserPrivacyLoadSuccessAction = {
  readonly type: typeof CURRENT_USER_PRIVACY_LOAD_SUCCESS
  readonly privacy_settings: UserPrivacySettings
}

type CurrentUserPrivacyUpdateRequestAction = {
  readonly type: typeof CURRENT_USER_PRIVACY_UPDATE_REQUEST
}

type CurrentUserPrivacyUpdateFailedAction = {
  readonly type: typeof CURRENT_USER_PRIVACY_UPDATE_FAILED
}

type CurrentUserPrivacyUpdateSuccessAction = {
  readonly type: typeof CURRENT_USER_PRIVACY_UPDATE_SUCCESS
  readonly privacy_settings: UserPrivacySettings
}

type Action =
  | CurrentUserLoadSuccessAction
  | CurrentUserCheckNotificationsSuccessAction
  | CurrentUserMarkNotificationsReadAction
  | CurrentUserCheckNotificationsAction
  | CurrentUserLogoutSuccessAction
  | CurrentUserLogoutErrorAction
  | CurrentUserLocaleChangedAction
  | CurrentUserLibraryLanguageChangedAction
  | CurrentUserUIUpdateAction
  | CurrentUserAvatarLoadSuccessAction
  | CurrentUserSettingsUpdateAction
  | CurrentUserPrivacyLoadAction
  | CurrentUserPrivacyLoadFailedAction
  | CurrentUserPrivacyLoadSuccessAction
  | CurrentUserPrivacyUpdateRequestAction
  | CurrentUserPrivacyUpdateFailedAction
  | CurrentUserPrivacyUpdateSuccessAction
  | CurrentUserSocialsUpdateAction
  | CurrentUserEmailUpdateSuccessAction
  | CurrentUserEmailUpdateAction
  | CurrentUserEmailUpdateErrorAction
  | LoadMailingGroupsAction
  | SubscribeToMailingGroupAction
  | UnsubscribeFromMailingGroupAction
  | CurrentUserLoadAction
  | CurrentUserSocialAuthSuccessAction
  | CurrentUserAuthFormLoginSuccess
  | CurrentUserLoadCompleteAction
  | CurrentUserLoadErrorAction
  | CurrentUserLoadDevicesSuccessAction
  | SendConfirmEmailMailAction
  | SendAppLinkAction
  | SendAppLinkSuccessAction
  | SendAppLinkErrorAction
  | SendConfirmEmailMailSuccessAction

export function sendConfirmEmailMail(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/email_confirmations/send',
      types: [SEND_CONFIRM_EMAIL_MAIL, SEND_CONFIRM_EMAIL_MAIL_SUCCESS],
      options: {
        method: 'post',
      },
      ignoreError: true,
      onSuccess(dispatch) {
        dispatch(
          showAlert('success', { message: 'alerts.verification_link_sent' }),
        )
      },
    },
  }
}

export function loadPrivacy(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/privacy_settings',
      types: [
        CURRENT_USER_PRIVACY_LOAD,
        CURRENT_USER_PRIVACY_LOAD_SUCCESS,
        CURRENT_USER_PRIVACY_LOAD_FAILED,
      ],
    },
  }
}

export function updatePrivacy(body: UserPrivacySettings): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/privacy_settings',
      options: {
        method: 'put',
        data: body,
      },
      types: [
        CURRENT_USER_PRIVACY_UPDATE_REQUEST,
        CURRENT_USER_PRIVACY_LOAD_SUCCESS,
        CURRENT_USER_PRIVACY_LOAD_FAILED,
      ],
    },
  }
}

export function loadContext(): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    dispatch({ type: CURRENT_USER_LOAD })

    const uri = '/p/api/v5/context'
    const state = getState()
    const options = {
      state: {
        app: {
          headers: state.app?.headers,
          connection: state.app?.connection,
          hostname: state.app?.hostname,
          csrfToken: state.app?.csrfToken,
        },
        recaptcha: state.recaptcha?.userToken,
        currentUser: {
          data: {
            library_lang: state.currentUser?.data?.library_lang,
          },
          auth: state.currentUser?.auth,
        },
      },
      method: 'get' as FetchMethod,
    }

    const { app } = state
    let lang = chooseLanguageFromRequestParameters(app)
    if (lang === 'rus') lang = 'ru'

    return await fetchWrapper.fetch(uri, options).then(res => {
      let { locale, library_lang } = res.context

      const {
        country,
        authenticated,
        in_team,
        family_management,
        family_plans_present,
        audio,
        comics,
        hide_gift_cards_and_promo,
      } = res.context

      if (lang && !authenticated) {
        if (lang === GHANA) {
          locale = 'en'
          library_lang = 'en'
        } else {
          locale = lang
          library_lang = lang
        }
      }

      const data = {
        country,
        locale,
        library_lang,
        family_management,
        family_plans_present,
        audio,
        comics,
        hide_gift_cards_and_promo,
      }

      dispatch({
        type: CURRENT_USER_LOAD_SUCCESS,
        currentUser: {
          auth: authenticated,
          team: in_team,
          data,
          ui: initialState.ui,
        },
      })

      if (in_team) {
        dispatch(setAvailableUserLanguages({ all: true }))
      }

      if (env.isProduction()) {
        const { codeValue } = state.code || {}
        let newLocation = getCorrectSubdomainLocation(app, locale, country)

        if (newLocation && codeValue) {
          newLocation += dedent`
            ${newLocation.includes('?') ? '&' : '?'}
            ${qs.stringify({ promo: codeValue })}`
        }

        if (newLocation) {
          dispatch(
            externalRedirect(
              addParamsToPath(
                newLocation,
                app.storedQuery as NullableQueryParams,
              ),
              {
                replace: true,
              },
            ),
          )
        }
      }
    })
  }
}

export function checkIfUnreadNotifications(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/notifications/status',
      types: [
        CURRENT_USER_CHECK_NOTIFICATIONS,
        CURRENT_USER_CHECK_NOTIFICATIONS_SUCCESS,
      ],
    },
  }
}

export function loadInfo(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile',
      modifyResponse: response => {
        return {
          currentUser: {
            auth: true,
            data: prepareUserData(response.user),
          },
        }
      },
      types: [CURRENT_USER_LOAD, CURRENT_USER_LOAD_SUCCESS],
    },
  }
}

export function loadDeviceInfo(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/devices',
      types: [CURRENT_USER_DEVICES_LOAD, CURRENT_USER_DEVICES_LOAD_SUCCESS],
    },
  }
}

export function deleteDevice(id: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/devices/${id}`,
      options: {
        method: 'delete',
      },
      types: [CURRENT_USER_DEVICE_DELETE],
      onSuccess: (dispatch: Dispatch): void => {
        dispatch(showAlert('success', { message: 'alerts.device_deleted' }))
      },
    },
  }
}

export function updateDevice(id: number, deviceState: FormData): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/devices/${id}`,
      options: {
        method: 'patch',
        isMultipart: true,
        dontParse: true,
        body: deviceState,
      },
      types: [CURRENT_USER_DEVICE_UPDATE],
    },
  }
}

function prepareUserData(userData) {
  const libraryLanguageCode = get(userData, 'library_lang.code')
  if (libraryLanguageCode && userData) {
    userData.library_lang = libraryLanguageCode
  } else userData.library_lang = 'en'
  userData = {
    ...omit(userData, 'mailing_subscriptions'),
  }

  return userData
}

export function flagUserDataAsCompletelyLoaded(): GenericDispatchedEvent {
  return { type: CURRENT_USER_COMPLETELY_LOADED }
}

export function logout(shouldBlockRedirect?: boolean): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const uri = `/logout`
    const state = getState()
    await fetchWrapper
      .fetch(uri, {
        state,
        method: 'delete',
      })
      .then(() => {
        dispatch({
          type: CURRENT_USER_LOGOUT_SUCCESS,
        })
        dispatch(hideAuthPopup())
        dispatch(subscriptionAccessLevelsClear())
        if (!shouldBlockRedirect) {
          dispatch(push(urlFor.root(state.app.storedQuery)))
        }
        dispatch(analyticsEvent(LOGGED_OUT))
        dispatch(cleanShowcase())
        storage.remove(USER_ID)
      })
      .catch(err => {
        dispatch({
          type: CURRENT_USER_LOGOUT_ERROR,
          error: err,
        })

        throw err
      })
  }
}

export function setLocale(locale: string, pathname?: string): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const uri = `/p/api/v5/locale`

    dispatch({
      type: CURRENT_USER_LOCALE_CHANGED,
      locale,
    })

    const state = getState()
    const isAuth = state.currentUser.auth

    await fetchWrapper
      .fetch(uri, {
        state,
        method: 'put',
        dontParse: true,
        data: { locale },
      })
      .then(() => {
        if (isAuth) dispatch(loadUserSubscriptions())
        const subdomain = getSubdomainFromLocale(locale)
        const newLocation = `${state.app.protocol}://${subdomain}${
          state.app.domain
        }${pathname || state.app.url}`

        if (env.isProduction()) {
          dispatch(
            externalRedirect(
              addParamsToPath(
                newLocation,
                state.app.storedQuery as NullableQueryParams,
              ),
              { replace: true },
            ),
          )
        } else {
          const actions = [
            dispatch(loadFeaturedProduct()),
            dispatch(
              languagesActions.getUserLanguageTranslations(
                state.currentUser.data.locale,
              ),
            ),
          ]
          Promise.all(actions)
        }
      })
  }
}

export function setLibraryLanguage(libraryLanguage: string): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const uri = `/p/a/4/user`

    const state = getState()
    const {
      currentUser: { auth },
    } = state

    dispatch({
      type: CURRENT_USER_LIBRARY_LANGUAGE_CHANGED,
      libraryLanguage,
    })

    if (!auth) {
      return
    }

    await fetchWrapper.fetch(uri, {
      state: getState(),
      method: 'put',
      data: {
        user: { library_lang: libraryLanguage },
      },
    })
  }
}

export function updateAvatar(payload: FormData): ThunkAction {
  return async dispatch => {
    dispatch({
      type: CURRENT_USER_UI_UPDATE,
      ui: {
        avatar: {
          loading: true,
        },
      },
    })

    try {
      const response = await dispatch(remoteProfileUpdate(payload, true))

      dispatch({
        type: CURRENT_USER_AVATAR_LOAD_SUCCESS,
        ui: {
          avatar: {
            loading: false,
            error: false,
          },
        },
        data: {
          avatar_url: response.avatar_url,
        },
      })
    } catch (error) {
      let message

      const responseError = error.error

      if (typeof responseError === 'string') {
        message = responseError
      } else if (responseError && typeof responseError === 'object') {
        message = responseError.message
      } else {
        message = UNKNOWN_ERROR_ID
      }

      dispatch({
        type: CURRENT_USER_UI_UPDATE,
        ui: {
          avatar: {
            loading: false,
            error: message,
          },
        },
      })
    }
  }
}

/*
 * Update profile settings that do not require involved interface interactions
 */
export function simpleProfileUpdate(
  payload: {
    [key: string]: string | boolean
  },
  dispatch: Dispatch,
  shortDebounce = false,
): void {
  dispatch({
    type: CURRENT_USER_SETTINGS_UPDATE,
    data: payload,
  })

  // api expects data to be wrapped in a user object
  const remoteUpdatePayload = {
    user: {
      ...payload,
    },
  }

  if (shortDebounce) {
    shortlyDebouncedRemoteProfileUpdate(remoteUpdatePayload, dispatch)
  } else {
    debouncedRemoteProfileUpdate(remoteUpdatePayload, dispatch)
  }
}

export async function updateUsername(
  username: string,
  confirmationMessage: string,
  onCancelSubmit: () => void,
  dispatch: Dispatch,
  query?: QueryParams,
) {
  const { error: usernameValidationError } = isUsernameValid(username)

  if (usernameValidationError) {
    return dispatch({
      type: CURRENT_USER_UI_UPDATE,
      ui: {
        login: {
          error: usernameValidationError,
        },
      },
    })
  }

  try {
    await checkUsernameAvailability(username)
    // if the above request finds a user, it means the selected username is used by someone else
    return dispatch({
      type: CURRENT_USER_UI_UPDATE,
      ui: {
        login: {
          error: 'usernameAlreadyTaken',
        },
      },
    })
  } catch (e) {
    // if the username is available, the previous request should have produced a 404 error
    if (!confirm(confirmationMessage)) {
      onCancelSubmit()
      return
    }

    try {
      await dispatch(remoteProfileUpdate({ user: { login: username } }))
      dispatch({
        type: CURRENT_USER_SETTINGS_UPDATE,
        data: { login: username },
        ui: {
          login: {
            error: false,
          },
        },
      })
      dispatch(replace(urlFor.settings(username, '', query)))
    } catch (er) {
      throw er
    }
  }
}

export async function changePassword(
  oldPassword: string,
  newPassword: string,
  dispatch: Dispatch,
): Promise<ApiAction> {
  try {
    const payload = {
      user: {
        onetime_confirmation_password: oldPassword,
        password: newPassword,
      },
    }

    dispatch({
      type: CURRENT_USER_SETTINGS_UPDATE,
      ui: {
        password: {
          error: false,
          loading: true,
        },
      },
    })

    await dispatch(remoteProfileUpdate(payload))

    return dispatch({
      type: CURRENT_USER_SETTINGS_UPDATE,
      ui: {
        password: {
          error: false,
          loading: false,
        },
      },
    })
  } catch (error) {
    dispatch({
      type: CURRENT_USER_UI_UPDATE,
      ui: {
        password: {
          loading: false,
          error: error.passwordValidationError
            ? error.passwordValidationError
            : 'passwordChangeUnauthorized',
        },
      },
    })
    throw error
  }
}

export function changeEmail({
  email,
  password = '',
  noAlert = false,
}: {
  email: string
  password?: string
  noAlert?: boolean
}): ApiAction {
  const payload: { user: { new_email: string; password?: string } } = {
    user: {
      new_email: email,
    },
  }

  if (password) payload.user.password = password

  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/email',
      options: {
        method: 'put',
        dontParse: true,
        data: payload,
      },
      onSuccess(dispatch, getState) {
        const {
          app,
          currentUser: {
            data: { login },
          },
        } = getState()
        if (noAlert) {
          dispatch(
            push(urlFor.addEmailVerificationSend(login, app.storedQuery)),
          )
        } else {
          dispatch(push(urlFor.settings(login, '', app.storedQuery)))
          dispatch(
            showAlert('success', { message: 'alerts.verification_link_sent' }),
          )
        }
      },
      onError(dispatch) {
        if (!noAlert)
          dispatch(showAlert('error', { message: 'errors.unknown' }))
      },
      types: [
        CURRENT_USER_EMAIL_UPDATE,
        CURRENT_USER_EMAIL_UPDATE_SUCCESS,
        CURRENT_USER_EMAIL_UPDATE_ERROR,
      ],
    },
  }
}

export function verifyEmail(token: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const url = `/p/api/v5/confirm_emails/${token}`
    const state = getState()
    const options = {
      method: 'put' as FetchMethod,
      state,
      dontParse: true,
    }
    const {
      currentUser: {
        data: { login, email },
      },
      app,
    } = state
    const successMessageId = email
      ? 'alerts.email_updated'
      : 'alerts.email_added'

    try {
      await fetchWrapper.fetch(url, options)
      if (login) {
        dispatch(replace(urlFor.settings(login, '', app.storedQuery)))
      }
      dispatch(showAlert('success', { message: successMessageId }))
      await dispatch(loadInfo())
    } catch (e) {
      if (login) {
        dispatch(replace(urlFor.settings(login, '', app.storedQuery)))
      }
      dispatch(showAlert('error', { message: 'errors.unknown' }))
    }
  }
}

export function changePhoneNumber(payload: {
  phone_number: string
  code: string
}): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const url = '/p/api/v5/profile/phone'
    const options = {
      state: getState(),
      method: 'put' as FetchMethod,
      data: payload,
    }

    try {
      dispatch({
        type: CURRENT_USER_UI_UPDATE,
        ui: {
          phone: {
            loading: true,
            confirmationCodeError: false,
          },
        },
      })

      await fetchWrapper.fetch(url, options)

      dispatch({
        type: CURRENT_USER_SETTINGS_UPDATE,
        ui: {
          phone: {
            loading: false,
          },
        },
        data: {
          phone_number: payload.phone_number,
        },
      })
    } catch (error) {
      dispatch({
        type: CURRENT_USER_UI_UPDATE,
        ui: {
          phone: {
            confirmationCodeError: error.error.message,
            loading: false,
          },
        },
      })
      throw error
    }
  }
}

export function requestPhoneRegistrationCode(payload: {
  phone_number: string
}): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const url = '/p/api/v5/yettel_serbia/send_code'

    const options = {
      state: getState(),
      method: 'post' as FetchMethod,
      data: payload,
    }

    try {
      dispatch({
        type: CURRENT_USER_UI_UPDATE,
        ui: {
          phone: {
            phoneNumberError: false,
            loading: true,
          },
        },
      })

      const response = await fetchWrapper.fetch(url, options)

      dispatch({
        type: CURRENT_USER_UI_UPDATE,
        ui: {
          phone: {
            loading: false,
          },
        },
      })
      if (response.code.length) {
        dispatch(analyticsEvent(VERIFICATION_CODE_REQUESTED))
        dispatch(changeStep('codeSent'))
      }
      return response
    } catch (error) {
      dispatch({
        type: CURRENT_USER_UI_UPDATE,
        ui: {
          phone: {
            loading: false,
            phoneNumberError: error.error.message,
          },
        },
      })
      throw error
    }
  }
}

export function requestConfirmationCode(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/send_code`,
      options: {
        method: 'post',
      },
      types: [REQUEST_CODE, REQUEST_CODE_SUCCESS],
    },
  }
}

function remoteProfileUpdate(payload, isFormData = false): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const uri = `/p/a/4/user`

    const options: FetchOptions = {}
    options.state = getState()
    options.method = 'put'

    if (isFormData) {
      options.isMultipart = true
      options.body = payload
    } else {
      options.data = payload
    }

    return await fetchWrapper.fetch(uri, options)
  }
}

const debouncedRemoteProfileUpdate = debounce((payload, dispatch) => {
  return dispatch(remoteProfileUpdate(payload))
}, DEBOUNCE_TIME)

const shortlyDebouncedRemoteProfileUpdate = debounce((payload, dispatch) => {
  return dispatch(remoteProfileUpdate(payload))
}, SHORT_DEBOUNCE_TIME)

function checkUsernameAvailability(username) {
  const url = `/p/api/v5/users/${username}`

  const options = {
    method: 'get' as FetchMethod,
  }

  return fetchWrapper.fetch(url, options)
}

export function subscribeToMailingGroup(mailingGroupName: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/mailing_groups/${mailingGroupName}`,
      options: {
        method: 'put',
      },
      ignoreError: true,
      types: [SUBSCRIBE_TO_MAILING_GROUP, SUBSCRIBE_TO_MAILING_GROUP_SUCCESS],
    },
  }
}

export function unsubscribeFromMailingGroup(
  mailingGroupName: string,
): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/mailing_groups/${mailingGroupName}`,
      options: {
        method: 'delete',
      },
      ignoreError: true,
      types: [
        UNSUBSCRIBE_FROM_MAILING_GROUP,
        UNSUBSCRIBE_FROM_MAILING_GROUP_SUCCESS,
      ],
    },
  }
}

export function loadMailingGroups(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/mailing_groups`,
      options: {
        method: 'get',
      },
      types: [LOAD_MAILING_GROUPS, LOAD_MAILING_GROUPS_SUCCESS],
    },
  }
}

export function disconnectFromSocialNetwork(provider: string): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const url = `/p/api/v5/profile/authentication_providers/${provider}`

    const options = {
      method: 'delete' as FetchMethod,
      state: getState(),
    }

    try {
      const {
        user: { social_networks },
      } = await fetchWrapper.fetch(url, options)

      dispatch({
        type: CURRENT_USER_SOCIALS_UPDATE,
        data: { social_networks },
      })
    } catch (error) {
      throw error
    }
  }
}

export function deleteAccount(payload: DeleteAccountPayload): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const url = '/p/a/4/user'

    const options = {
      method: 'delete' as FetchMethod,
      state: getState(),
      data: payload,
    }

    dispatch({
      type: CURRENT_USER_UI_UPDATE,
      ui: {
        deleteAccount: {
          loading: true,
          error: false,
        },
      },
    })

    try {
      await fetchWrapper.fetch(url, options)
      dispatch(showAlert('success', { message: 'profile.account_deleted' }))
      dispatch(analyticsEvent(ACCOUNT_DELETED))
      return dispatch(logout())
    } catch (error) {
      let errorMessage = ''
      if (typeof error === 'string') {
        errorMessage = error
      } else if (error && typeof error === 'object') {
        errorMessage = error.error?.message
      } else {
        errorMessage = UNKNOWN_ERROR_ID
      }

      dispatch({
        type: CURRENT_USER_UI_UPDATE,
        ui: {
          deleteAccount: {
            loading: false,
            error: errorMessage,
          },
        },
      })
    }
  }
}

function chooseLanguageFromRequestParameters({ host, query }) {
  // language hierarchy: the language set in query parameters is more important
  // than the language of subdomain
  if (query && query.lang) {
    return query.lang
  } else {
    return getSubdomainFromHostname(host)
  }
}

export const GHANA = 'gh'

function navigateGhana({ host, protocol, url }) {
  let newLocation = `${protocol}://${GHANA}.${getDomain(host)}${url}`
  if (env.isClient()) newLocation += window.location.hash
  return newLocation
}

function getCorrectSubdomainLocation(
  { host, protocol, url, ua: { ua } },
  locale: Locale,
  country: string,
) {
  if (isKnownBotUa(ua)) return

  const currentSubdomain = getSubdomainFromHostname(host)

  if (country === GHANA && currentSubdomain !== GHANA)
    return navigateGhana({ host, protocol, url })
  else if (country === GHANA && currentSubdomain === GHANA) return

  if (!currentSubdomain && locale === config.fallbackLocale) return
  const isBmRu = currentSubdomain === 'rus' && locale === 'ru'

  if (currentSubdomain !== locale && !isBmRu) {
    const subdomain = getSubdomainFromLocale(locale)
    let newLocation = `${protocol}://${subdomain}${getDomain(host)}${url}`

    if (!subdomain && !currentSubdomain) return

    if (env.isClient()) {
      newLocation += window.location.hash
    }

    return newLocation
  }
}

/**
 * Add the `team: true` property if the api responds that the user is `in_team` (i.e. has admin privileges)
 * Do not add this property if in the action it is set to false (not to show this property to the rest of the world)
 */
function withTeam(state, inTeam) {
  const teamPropContainer: { team?: boolean } = {}

  if (inTeam) {
    teamPropContainer.team = true
  }

  return { ...state, ...teamPropContainer }
}

export const initialState: CurrentUserState = {
  ui: {
    /* UI-related data for user profile settings */
    avatar: {
      loading: false,
      error: false,
    },
    login: {
      error: false,
    },
    password: {
      loading: false,
      error: false,
    },
    email: {
      loading: false,
      error: false,
    },
    deleteAccount: {
      loading: false,
      error: false,
    },
    phone: {
      loading: false,
      phoneNumberError: false,
      confirmationCodeError: false,
    },
    devices: {
      data: [],
      loaded: false,
    },
    privacy: {
      loading: false,
      error: false,
      data: {
        content_visibility: '',
        bookshelves_visibility: '',
        new_bookshelf_visible: true,
      },
    },
  },
  mailingGroups: [],
  data: {
    smsLink: {
      success: false,
      loading: false,
      error: '',
    },
    email_verified: true,
    id: null,
    login: '',
    name: '',
    email: '',
    locale: config.fallbackLocale as Locale,
    country: '',
    library_lang: '',
    audio: false,
    comics: false,
    avatar: {
      large: '',
      small: '',
    },
    family_management: false,
    family_plans_present: false,
    social_networks: [],
  },
  auth: false,
  loading: false,
  loaded: false,
  completelyLoaded: false,
  error: null,
  socialProvider: '',
}

export default function currentUser(
  state: CurrentUserState = initialState,
  action: Action,
) {
  switch (action.type) {
    case APP_LINK_SEND:
      return {
        ...state,
        smsLink: {
          success: false,
          loading: true,
          error: '',
        },
      }

    case APP_LINK_SEND_SUCCESS:
      return {
        ...state,
        smsLink: {
          success: true,
          loading: false,
          error: '',
        },
      }

    case APP_LINK_SEND_ERROR:
      return {
        ...state,
        smsLink: {
          success: false,
          loading: false,
          error: action.error,
        },
      }

    case CURRENT_USER_LOAD:
      return {
        ...state,
        loading: true,
        completelyLoaded: false,
      }

    case CURRENT_USER_LOAD_SUCCESS:
      return withTeam(
        {
          ...state,
          auth: action.currentUser.auth,
          data: {
            ...state.data,
            ...action.currentUser.data,
          },
          loading: false,
          loaded: true,
          error: null,
        },
        action.currentUser.team,
      )

    case CURRENT_USER_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
      }

    case CURRENT_USER_COMPLETELY_LOADED:
      return {
        ...state,
        completelyLoaded: true,
      }

    case CURRENT_USER_DEVICES_LOAD_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          devices: {
            loaded: true,
            data: action.devices,
          },
        },
      }

    case CURRENT_USER_PRIVACY_LOAD:
      return {
        ...state,
        ui: {
          ...state.ui,
          privacy: {
            ...state.ui.privacy,
            loading: true,
            error: false,
          },
        },
      }

    case CURRENT_USER_PRIVACY_LOAD_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          privacy: {
            ...state.ui.privacy,
            loading: false,
            error: true,
          },
        },
      }

    case CURRENT_USER_PRIVACY_LOAD_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          privacy: {
            loading: false,
            error: false,
            data: action.privacy_settings,
          },
        },
      }

    case CURRENT_USER_PRIVACY_UPDATE_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          privacy: {
            ...state.ui.privacy,
            loading: true,
            error: false,
          },
        },
      }

    case CURRENT_USER_PRIVACY_UPDATE_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          privacy: {
            ...state.ui.privacy,
            loading: false,
            error: true,
          },
        },
      }

    case CURRENT_USER_PRIVACY_UPDATE_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          privacy: {
            ...state.ui.privacy,
            loading: false,
            data: action.privacy_settings,
          },
        },
      }

    case AUTH_FORM_LOGIN_SUCCESS:
      return {
        ...state,
        auth: true,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          ...prepareUserData(action.data),
        },
      }

    case CURRENT_USER_LOGOUT_SUCCESS:
      return {
        ...initialState,
        data: {
          ...initialState.data,
          locale: state.data.locale,
          country: state.data.country,
        },
        socialProvider: '',
      }

    case SOCIAL_AUTH_SUCCESS:
      return {
        ...state,
        auth: true,
        socialProvider: action.provider,
      }

    case CURRENT_USER_LOCALE_CHANGED:
      return {
        ...state,
        data: {
          ...state.data,
          locale: action.locale,
        },
      }

    case CURRENT_USER_LIBRARY_LANGUAGE_CHANGED:
      return {
        ...state,
        data: {
          ...state.data,
          library_lang: action.libraryLanguage,
        },
      }

    case CURRENT_USER_UI_UPDATE:
      return {
        ...state,
        ui: merge({ ...state.ui }, { ...action.ui }),
      }

    case CURRENT_USER_AVATAR_LOAD_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          ...action.ui,
        },
        data: {
          ...state.data,
          ...action.data,
        },
      }

    case CURRENT_USER_SETTINGS_UPDATE:
      return {
        ...state,
        data: merge({}, state.data, action.data),
        ui: merge({}, state.ui, action.ui),
      }

    case CURRENT_USER_SOCIALS_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          social_networks: action.data.social_networks,
        },
      }

    case CURRENT_USER_EMAIL_UPDATE:
      return {
        ...state,
        ui: merge({}, state.ui, { email: { loading: true, error: false } }),
      }

    case CURRENT_USER_EMAIL_UPDATE_SUCCESS:
      return {
        ...state,
        ui: merge({}, state.ui, { email: { loading: false, error: false } }),
      }

    case CURRENT_USER_EMAIL_UPDATE_ERROR:
      return {
        ...state,
        ui: merge({}, state.ui, {
          email: { loading: false, error: action.error.message },
        }),
      }

    case CURRENT_USER_CHECK_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          has_unread_notifications: action.has_unread_notifications,
        },
      }

    case NOTIFICATIONS_MARK_AS_READ:
      return {
        ...state,
        data: {
          ...state.data,
          has_unread_notifications: false,
        },
      }

    case LOAD_MAILING_GROUPS_SUCCESS:
      return {
        ...state,
        mailingGroups: action.mailing_groups,
      }

    default:
      return state
  }
}
