import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Trans } from '@lingui/react'
import isEmpty from 'lodash/isEmpty'
import compose from 'lodash/fp/compose'
import SVGInline from 'react-svg-inline'
import * as codeActions from 'client/bookmate/reducers/code-reducer'

import errorBoundary, {
  ErrorBoundaryProps,
} from 'client/shared/decorators/error-boundary'

import { show as showAuthPopup } from 'client/shared/reducers/popup-reducer'

import {
  follow as followShelf,
  unfollow as unfollowShelf,
} from 'client/bookmate/reducers/shelves-reducer'

import {
  follow as followUser,
  unfollow as unfollowUser,
} from 'client/bookmate/reducers/users-reducer'

import MarkIcon from 'client/shared/icons/mark.svg'
import Button from 'client/shared/blocks/button'

import { UserShort } from 'client/shared/types/user'
import { ShelfProps } from 'client/shared/types/shelf'
import { Dispatch, State } from 'shared/types/redux'
import { AuthorProps } from 'client/shared/types/author'
import {
  subscribeToAuthor,
  unsubscribeFromAuthor,
} from '../reducers/author-reducer'
import subscriptionHelper from 'client/shared/helpers/subscription-helper'
import { getUserAccessLevels } from '../selectors/subscription-selectors'
import { State as SubscriptionState } from 'client/bookmate/reducers/subscription-reducer'

type Props = {
  dispatch: Dispatch
  userAccessLevels: SubscriptionState['userAccessLevels']
  auth: boolean
  following: boolean
  width: string
  className?: string
  user?: UserShort
  shelf?: ShelfProps
  onFollowSuccess?: () => void
  author?: AuthorProps
  country?: string
} & ErrorBoundaryProps

export const DK_SHELF_UUID = 'wWByRpEC'
export const INCUBA_SHELF_UUID = 'Gkd1gxl1'
export const FRIFERIE_SHELF_UUID = 'coz1qxvs'

export const FOLJETON_CODE = 'FOLJETON2024'
export const FOLJETON_SHELF_UUID = 'RgPczlmI'

export const DK_CODE = 'BIOGRAFKLUBDK'

export const DK_SUMMER_SHELF_UUID = 'hqmcoe7O'
export const DK_SUMMER_CODE = 'SOMMER2023'

export const LENUSBOOKCLUB1_CODE = 'LENUSBOOKCLUB1'
export const LENUSBOOKCLUB6_CODE = 'LENUSBOOKCLUB6'
export const LENUSBOOKCLUB_SHELF_UUID = 'c3bKimpd'

class FollowButtonBox extends Component<Props> {
  static defaultProps = {
    following: false,
    width: '160px',
  }

  render(): JSX.Element {
    const { following, width, className } = this.props

    const kind = following ? 'transparent' : 'primary'
    const intlId = following ? 'profile.unfollow' : 'shelf.follow'

    return (
      <Button
        kind={kind}
        type="inline"
        width={width}
        onClick={this.onButtonClick}
        className={`${className ? className : ''}`}
      >
        {following && (
          <SVGInline svg={MarkIcon} className="button__inline-icon" />
        )}
        <Trans id={intlId} />
      </Button>
    )
  }

  onButtonClick = (e: React.SyntheticEvent<HTMLElement>): void => {
    e.preventDefault()

    const { dispatch, auth } = this.props

    if (auth) {
      this.followHandler()
    } else {
      dispatch(showAuthPopup({ callback: this.followHandler }))
    }
  }

  isDanishChallenge = (uuid: string): void => {
    const { userAccessLevels, dispatch } = this.props
    const hasSubscription = subscriptionHelper.hasAnySubscriptions(
      userAccessLevels,
    )
    if (
      ![DK_SHELF_UUID, DK_SUMMER_SHELF_UUID].includes(uuid) ||
      hasSubscription
    )
      return

    const codeValue = uuid === DK_SHELF_UUID ? DK_CODE : DK_SUMMER_CODE

    dispatch(
      codeActions.checkCode({
        codeValue,
        query: `${location.search}${
          location.search.includes('?') ? '&' : '?'
        }shelf=${uuid}`,
      }),
    )
  }

  followHandler = (): void => {
    const {
      dispatch,
      shelf,
      user,
      onFollowSuccess,

      author,
      following,
      userAccessLevels,
    } = this.props
    const hasSubscription = subscriptionHelper.hasAnySubscriptions(
      userAccessLevels,
    )

    if (shelf && !isEmpty(shelf)) {
      if (shelf.following) {
        dispatch(unfollowShelf(shelf.uuid))
      } else {
        dispatch(
          followShelf(
            shelf.uuid,
            { shelf_id: shelf.uuid },
            onFollowSuccess,
            [DK_SHELF_UUID, DK_SUMMER_SHELF_UUID].includes(shelf.uuid) &&
              hasSubscription
              ? DK_SHELF_UUID
              : '',
          ),
        )
        this.isDanishChallenge(shelf.uuid)
      }
    }

    if (user && !isEmpty(user)) {
      if (user.following) {
        dispatch(unfollowUser(user))
      } else {
        dispatch(followUser(user.login, { followee_id: user.id }))
      }
    }

    if (author && !isEmpty(author)) {
      if (following) {
        dispatch(unsubscribeFromAuthor(author.uuid))
      } else {
        dispatch(subscribeToAuthor(author.uuid))
      }
    }
  }
}

const connectWrapper = connect((state: State) => ({
  auth: state.currentUser.auth,
  userAccessLevels: getUserAccessLevels(state),
  country: state.currentUser.data.country,
}))

const wrappers = compose(connectWrapper, errorBoundary)

export default wrappers(FollowButtonBox)
